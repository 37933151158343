import { ISocialContentService } from '@wix/social-groups-api';

import {
  CommentsApiTypes,
  FeedApiTypes,
  ReactionsApiTypes,
} from '@wix/social-groups-api/dist/src/types';
import { action, flow, observable } from 'mobx';
import { list, object, raw, serializable } from 'serializr';
import Channel from '@wix/duplexer-js/dist/src/channels/channel';

import { FeedItem } from './FeedItem';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import { ContentConverter } from '../../../../../common/ContentEditor/content/ContentConverter';
import { ITopic } from '../../../types/ITopic';
import { IFeedItemEntity, IFeedStore } from '../../../types/IFeedItem';

const FETCH_FEED_FIELDSET = 'comments,reactions,requesterContext';

export const DEFAULT_FEED_LIMIT = 10;
export const FEED_ITEMS_QUEUE_CURSOR = 'feedItemsQueue';
export class FeedStore implements IFeedStore {
  @serializable(list(object(FeedItem)))
  @observable.shallow
  feedItems: FeedItem[] = [];

  @serializable(list(raw()))
  @observable.shallow
  topics: ITopic[] = [];

  private feedItemsMap: Map<string, FeedItem> = new Map<string, FeedItem>();
  private feedItemsQueue: FeedItem[] = [];

  @serializable @observable loading: boolean = false;

  @serializable @observable cursor: string | null = null;
  @serializable @observable prevCursor: string | null = null;

  public channel?: Channel;
  public repository: ISocialContentService;
  private useQueue!: boolean;

  constructor(repository: ISocialContentService) {
    this.repository = repository;
  }

  connectChannel(channel?: Channel) {
    this.channel = channel;

    if (!this.channel) {
      return;
    }

    this.channel.on('@duplexer:subscription_failed', (error: any) => {
      console.log('@duplexer:subscription_failed: ', error);
    });

    this.channel.on(
      'wix_feed_callbacks_ReactedOnFeedItem',
      this.onReactedOnFeedItem.bind(this),
    );
  }

  @action
  onReactedOnFeedItem(item: FeedApiTypes.callbacks.ReactedOnFeedItem) {
    const feedItem = this.findFeedItem(item.feedItemId!);

    // maybe not loaded yet
    if (!feedItem) {
      return;
    }

    const userReaction: ReactionsApiTypes.UserReaction = {
      userId: item.createdBy!.identityId,
      reaction: item.reaction,
    };

    // probably same tab, so no action needed
    if (feedItem.reactions.has(userReaction)) {
      return;
    }

    feedItem.reactions.add(userReaction);
  }

  @action fetch = flow(function* (this: FeedStore, limit?: number) {
    this.loading = true;
    this.feedItemsMap = new Map();
    this.cursor = null as any;
    this.useQueue = limit! < DEFAULT_FEED_LIMIT;
    try {
      yield this.addItemsFromRepository(limit);
    } finally {
      this.loading = false;
    }
  });

  @action fetchTopics = flow(function* (this: FeedStore) {
    try {
      const [{ data: topicsStats }, { topics }] = yield Promise.all([
        this.repository.feed.topicsStats({}),
        this.repository.topics.list({
          paging: {
            limit: 100,
          },
        }),
      ]);

      const stats = topicsStats.topicCounts;

      this.topics = topics
        .map((topic: any) => ({
          ...topic,
          count: stats[topic.id] || 0,
        }))
        .sort((a: any, b: any) => (b.count > a.count ? 1 : -1));
    } catch (error) {
      console.error(`FeedStore fetchTopics`, error);
    }
  });

  @action createTopic = flow(function* (this: FeedStore, displayName: string) {
    const { data } = yield this.repository.feed.createTopic({ displayName });

    this.topics.push({
      id: data.id,
      displayName,
      createdDate: new Date(),
      count: 0,
    });
  });

  @action fetchMore = flow(function* (this: FeedStore) {
    this.addItemsFromQueue();
    if (!this.cursor) {
      return;
    }
    yield this.addItemsFromRepository();
  });

  private async addItemsFromRepository(limit?: number) {
    const response = await this.repository.feed.list(this.getFeedListQuery());

    const feedItems = response.data.feedItems!.map(
      (feedItem) => new FeedItem(feedItem, this.repository),
    );

    if (this.useQueue && limit) {
      this.feedItemsQueue = feedItems.slice(limit);
      this.addFeedItems(feedItems.slice(0, limit));
    } else if (this.useQueue && !limit) {
      this.feedItemsQueue = feedItems;
    } else {
      this.addFeedItems(feedItems);
    }
    const { nextPageCursor, prevPageCursor } = response.data;
    this.setCursor(nextPageCursor!, prevPageCursor!);
  }

  private getFeedListQuery() {
    if (!this.cursor || this.cursor === FEED_ITEMS_QUEUE_CURSOR) {
      return {
        fieldset: FETCH_FEED_FIELDSET,
        query: {
          limit: DEFAULT_FEED_LIMIT,
        },
      };
    }
    return {
      fieldset: FETCH_FEED_FIELDSET,
      cursor: {
        cursor: this.cursor,
        limit: DEFAULT_FEED_LIMIT,
      },
    };
  }

  private addItemsFromQueue() {
    if (this.feedItemsQueue.length) {
      this.addFeedItems(this.feedItemsQueue);
      this.clearQueue();
    }
  }

  private clearQueue() {
    this.feedItemsQueue = [];
    if (this.cursor === FEED_ITEMS_QUEUE_CURSOR) {
      this.cursor = null as any;
    }
  }

  @action filter = flow(function* (this: FeedStore, filter) {
    this.loading = true;
    try {
      const { data } = yield this.repository.feed.search({
        fieldsets: FETCH_FEED_FIELDSET.split(','),
        filter,
      });

      this.cursor = null as any;
      this.feedItemsQueue = [];
      this.feedItemsMap = new Map();
      this.addFeedItems(
        data.feedItems.map(
          (feedItem: any) => new FeedItem(feedItem, this.repository),
        ),
      );
    } catch (error) {
      console.error(`FeedStore filter`, filter, error);
    } finally {
      this.loading = false;
    }
  });

  @action
  updateTopicCounter(topicId: string, count: number) {
    if (!topicId) {
      return;
    }

    const topic = this.topics.find((topicEl) => topicEl.id === topicId);

    if (!topic) {
      return;
    }

    topic.count! += count;
  }

  @action create = flow(function* (
    this: FeedStore,
    entity: FeedApiTypes.FeedItemEntity,
  ) {
    const response = yield this.repository.feed.create({
      entity,
      fieldset: 'comments,requesterContext',
    });

    const feedItem = new FeedItem(response.data, this.repository);
    this.feedItemsMap.set(feedItem.feedItemId, feedItem);
    this.feedItems.unshift(feedItem);
    this.updateTopicCounter(feedItem.entity.topics[0], +1);
  });

  @action update = flow(function* (
    this: FeedStore,
    feedItemId: string,
    entity: FeedApiTypes.FeedItemEntity,
  ) {
    yield this.updateFeedItem(feedItemId, entity);
  });

  private async updateFeedItem(
    feedItemId: string,
    entity: FeedApiTypes.FeedItemEntity,
  ) {
    const { data: updated } = await this.repository.feed.update({
      feedItemId,
      entity,
      updateMask: {
        paths: [
          'entity.body',
          'entity.attachments',
          'entity.externalReferences',
          'entity.topics',
        ],
      },
    });
    const feedItem = this.feedItemsMap.get(updated.feedItemId!);
    this.updateTopicCounter(feedItem!.entity.topics[0], -1);
    feedItem!.setEntity(updated.entity as IFeedItemEntity, updated.updatedAt!);
    this.updateTopicCounter(updated.entity!.topics![0], +1);
  }

  @action delete = flow(function* (this: FeedStore, feedItemId: string) {
    if (this.feedItemsMap.has(feedItemId)) {
      const feedItem = this.feedItemsMap.get(feedItemId);
      yield this.repository.feed.delete(feedItemId);
      this.feedItemsMap.delete(feedItemId);
      this.setFeedItems();

      // Activity post doesn't have entity
      if (feedItem!.entity && feedItem!.entity.topics) {
        this.updateTopicCounter(feedItem!.entity.topics[0], -1);
      }
    }
  });

  @action pin = flow(function* (this: FeedStore, feedItemId: string) {
    try {
      const pinnedFeedItem = this.feedItems.find((feedItem) => feedItem.pin);
      if (pinnedFeedItem) {
        yield this.repository.feed.unpinFromContainer(
          pinnedFeedItem.feedItemId,
        );
      }
      yield this.repository.feed.pinToContainer(feedItemId);
      yield this.fetch();
    } catch (error) {
      console.error('FeedStore FeedItem pin error: ', error);
    }
  });

  @action unpin = flow(function* (this: FeedStore, feedItemId: string) {
    try {
      yield this.repository.feed.unpinFromContainer(feedItemId);
      yield this.fetch();
    } catch (error) {
      console.error('FeedStore FeedItem unpin error: ', error);
    }
  });

  @action follow = flow(function* (this: FeedStore, feedItemId: string) {
    try {
      yield this.repository.feed.subscribeToFeedItem(feedItemId);

      const feedItem = this.feedItemsMap.get(feedItemId);

      if (feedItem) {
        feedItem.requesterContext.subscribed = true;
      }
    } catch (error) {
      console.error('FeedStore FeedItem follow error: ', error);
    }
  });

  @action unfollow = flow(function* (this: FeedStore, feedItemId: string) {
    try {
      yield this.repository.feed.unsubscribeFromFeedItem(feedItemId);

      const feedItem = this.feedItemsMap.get(feedItemId);

      if (feedItem) {
        feedItem.requesterContext.subscribed = false;
      }
    } catch (error) {
      console.error('FeedStore FeedItem unfollow error: ', error);
    }
  });

  /**
   * @deprecated
   * use addReaction
   * */
  @action react = flow(function* (
    this: FeedStore,
    feedItemId: string,
    reaction: ReactionsApiTypes.Reaction,
  ) {
    return this.feedItems
      .find((feedItem) => feedItem.feedItemId === feedItemId)!
      .react(reaction);
  });

  /**
   * @deprecated
   * use removeReaction
   * */
  @action unreact = flow(function* (
    this: FeedStore,
    feedItemId: string,
    reactionCode: string,
  ) {
    return this.feedItems
      .find((feedItem) => feedItem.feedItemId === feedItemId)!
      .unreact(reactionCode);
  });

  @action addReaction = flow(function* (
    this: FeedStore,
    feedItemId: string,
    userReaction: ReactionsApiTypes.UserReaction,
  ) {
    return this.feedItems
      .find((feedItem) => feedItem.feedItemId === feedItemId)!
      .addUserReaction(userReaction);
  });

  @action removeReaction = flow(function* (
    this: FeedStore,
    feedItemId: string,
    userReaction: ReactionsApiTypes.UserReaction,
  ) {
    return this.feedItems
      .find((feedItem) => feedItem.feedItemId === feedItemId)!
      .removeUserReaction(userReaction);
  });

  comment = flow(function* (
    this: FeedStore,
    feedItemId: string,
    comment: CommentsApiTypes.CommentEntity,
  ) {
    return this.feedItems
      .find((feedItem) => feedItem.feedItemId === feedItemId)!
      .comment(comment);
  });

  addComment(feedItemId: string, comment: any) {
    const feedItem = this.feedItemsMap.get(feedItemId);
    if (feedItem) {
      feedItem.addComment(comment);
    }
  }

  removeComment(feedItemId: string, commentId: string) {
    const feedItem = this.feedItemsMap.get(feedItemId);
    if (feedItem) {
      feedItem.removeComment(commentId);
    }
  }

  private addFeedItems(arr: FeedItem[]) {
    this.addFeedItemsToMap(arr);
  }

  private addFeedItemsToMap(feedItems: FeedItem[]) {
    for (const feedItem of feedItems) {
      if (!feedItem.activity || feedItem.hasValidActivity()) {
        this.feedItemsMap.set(feedItem.feedItemId, feedItem);
      }
    }
    this.setFeedItems();
  }

  private setFeedItems() {
    this.feedItems = Array.from(this.feedItemsMap.values());
  }

  private findFeedItem(id: string) {
    return this.feedItems.find((feedItem) => feedItem.feedItemId === id);
  }

  updateFeedItemsRepository() {
    this.feedItems.forEach((fI) => fI.setRepository(this.repository));
  }

  setCursor(cursor?: string, prevCursor?: string) {
    this.cursor = cursor || null;
    if (!this.cursor && this.feedItemsQueue.length) {
      this.cursor = FEED_ITEMS_QUEUE_CURSOR;
    }
    this.prevCursor = prevCursor || null;
  }

  private getRawDraftContent(
    feedItems: FeedItem[],
  ): RawDraftContentState<any>[] {
    return feedItems
      .filter((item) => !!item.getContent())
      .map((item) => {
        return ContentConverter.parseContentString(item.getContent());
      });
  }
}
